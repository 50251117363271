import { Container, Grid } from "@material-ui/core";
import { styled } from "linaria/react";
import { rgba } from "polished";
import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { theme } from "../../Shared/Theme/Theme";
import { QuestionAnswer } from "./components/QuestionAnswer";
import { useHomeFaqs } from "./hooks/useHomeFaqs";

export interface FaqProps {
	className?: string;
}

const QuestionOption = styled.div<{ selected: boolean }>`
  font-size: 1.8rem;
  margin: 20px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;

  border: 2px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 200ms ease-in-out;

  color: ${(props) =>
		props.selected ? theme.color.primary : theme.color.text};

  cursor: pointer;
`;

const IconWrapper = styled.div<{ selected: boolean }>`
  font-size: 2.8rem;
  font-weight: 300;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  svg {
    transition: all 200ms ease-in-out;
    color: ${(props) =>
		props.selected ? theme.color.primary : rgba(theme.color.text, 0.1)};
  }
`;

const FaqInner = (props: FaqProps) => {
	const questions = useHomeFaqs();
	const [questionIndex, setQuestionIndex] = useState(0);

	return (
		<Section className={props.className}>
			<Container>
				<Heading heading="Stále nerozhodnutý ?" />
				<Grid container spacing={6}>
					<Grid item xs={12} md={6}>
						{questions.map((question, index) => (
							<div
								data-sal-duration="700"
								data-sal="slide-up"
								data-sal-delay={index * 150}
								data-sal-easing="ease"
								key={question.slug}
							>
								<QuestionOption
									selected={index === questionIndex}
									onClick={() => setQuestionIndex(index)}
								>
									{question.question}
									<IconWrapper selected={index === questionIndex}>
										<BsChevronRight />
									</IconWrapper>
								</QuestionOption>
							</div>
						))}
					</Grid>
					<Grid
						data-sal-duration="700"
						data-sal="slide-up"
						data-sal-delay={600}
						data-sal-easing="ease"
						item
						container
						xs={12}
						md={6}
						alignItems="center"
						justify="center"
					>
						<QuestionAnswer {...questions[questionIndex]} />
					</Grid>
				</Grid>
			</Container>
		</Section>
	);
};

export const Faq = FaqInner;
