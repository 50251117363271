import { Grid, useMediaQuery } from "@material-ui/core";
import { styled } from "linaria/react";
import React from "react";
import { GalleryPhoto } from "../../Home/Gallery/hooks/useHomeGalleryPhotos";
import { Section } from "../Section/Section";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import { muiTheme } from "../Theme/Theme";
import GatsbyImage from "gatsby-image";
import { css } from "linaria";

export interface UniversalGalleryProps {
  className?: string;
  images: GalleryPhoto[];
  galleryKey?: string;
}

const ImageCard = styled.a`
  height: 300px;

  margin: auto;

  ${muiTheme.breakpoints.down("sm")} {
    height: 100px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const imageStyle = css`
  filter: drop-shadow(0px 6.60449px 16.5112px rgba(0, 0, 0, 0.4));
  border-radius: 4px;

  ${muiTheme.breakpoints.down("sm")} {
    filter: drop-shadow(0px 6.60449px 10px rgba(0, 0, 0, 0.2));
  }
`;

const UniversalGalleryInner = (props: UniversalGalleryProps) => {
  const { images, galleryKey } = props;
  const matches = useMediaQuery(muiTheme.breakpoints.down("sm"));

  return (
    <div className={props.className}>
      <SimpleReactLightbox>
        <SRLWrapper key={galleryKey}>
          <Grid container spacing={matches ? 3 : 6}>
            {images.map((image, index) => (
              <Grid
                data-sal-duration="700"
                data-sal="slide-up"
                data-sal-delay={index * 50}
                data-sal-easing="ease"
                item
                xs={6}
                md={6}
                lg={4}
              >
                <ImageCard key={image.publicURL} href={image.publicURL}>
                  {image.sources ? (
                    <GatsbyImage
                      className={imageStyle}
                      fluid={image.sources}
                    />
                  ) : (
                    <GatsbyImage
                      className={imageStyle}
                      fluid={image.childImageSharp.fluid}
                    />
                  )}
                </ImageCard>
              </Grid>
            ))}
          </Grid>
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
};

export const UniversalGallery = styled(UniversalGalleryInner)``;
