import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { generateCarRoute } from "../../../../../lib/routes";
import SvgDotPattern from "../../../../Shared/misc/DotPattern/DotPattern";
import { muiTheme, theme } from "../../../../Shared/Theme/Theme";
import { GridCar } from "../../hooks/useGridCars";

export interface CarGridItemProps extends GridCar {
  className?: string;
}

const CarName = styled.div`
  font-weight: 400;
  font-size: 1.8rem;
  text-decoration: none;
  color: ${theme.color.text};
  margin-top: 20px;
  text-align: center;
`;

const patternStyles = css`
  position: absolute;
  right: 20px;
  top: -30px;
  z-index: -1;
  fill: ${theme.color.primaryDark};

  ${muiTheme.breakpoints.down("sm")} {
    top: -60px;
    right: -20px;
  }
`;

const CarGridItemInner = (props: CarGridItemProps) => {
  const { name, image, slug } = props;
  return (
    <article className={props.className}>
      <Link to={generateCarRoute(slug)}>
        <div className="image-wrapper">
          <GatsbyImage className="car-image" fluid={image} />
          <SvgDotPattern className={patternStyles} />
        </div>
        <CarName>{name}</CarName>
      </Link>
    </article>
  );
};

export const CarGridItem = styled(CarGridItemInner)`
  text-decoration: none;
  position: relative;
  margin-bottom: 70px;

  .car-image,
  .${patternStyles} {
    transition: all 230ms ease-in-out;
  }

  .car-image {
    width: 70%;
    margin: auto;
  }

  ${muiTheme.breakpoints.down("sm")} {
    .car-image {
      width: 90%;
      margin: auto;
    }
    margin-bottom: 40px;
  }

  &:hover {
    .car-image {
      transform: scale(1.05);
    }

    .${patternStyles} {
      fill: ${theme.color.primary};
      fill-opacity: 0.25 !important;
    }
  }
`;
