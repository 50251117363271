import { Container, Grid, Hidden } from "@material-ui/core";
import GatsbyImage, { FluidObject } from "gatsby-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Heading, underlineStyle } from "../../../../Shared/Heading/Heading";
import { muiTheme, theme } from "../../../../Shared/Theme/Theme";
import { InteriorElement } from "../../hooks/useInteriorElements";

export interface ImageInfoSectionProps extends InteriorElement {
  className?: string;
  imageFirst?: boolean;
}

const TextSection = styled.div`
  width: 60%;

  ${muiTheme.breakpoints.down("sm")} {
    width: 90%;
  }
`;

const headingStyles = css`
margin-bottom: 7rem
  ${muiTheme.breakpoints.down("sm")} {
    margin-bottom: 50px;
  }
`;

const Text = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  color: ${theme.color.text};
`;

const ImageWrapper = styled.div`
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.4));
  border-radius: 4px;
`;

const imageStyles = css``;

const ImageSectionWrapper = styled.article<ImageInfoSectionProps>`
  text-align: ${(props) => (!props.imageFirst ? "left" : "right")};

  .heading {
    font-weight: 300;
    font-size: 3.6rem;

    &::after {
      right: ${(props) => (props.imageFirst ? 0 : "initial")};
    }
  }

  &:not(:last-child) {
    margin-bottom: 12rem;
  }
`;

const ConfigurationImage = (props: any) => {
  const { image } = props;
  return (
    <Grid item xs={12} md={6}>
      <ImageWrapper>
        <GatsbyImage className={imageStyles} fluid={image} />
      </ImageWrapper>
    </Grid>
  );
};

const ImageInfoSectionInner = (props: ImageInfoSectionProps) => {
  const { heading, image, text, imageFirst = false } = props;
  return (
    <ImageSectionWrapper {...props}>
      <Grid container spacing={2} zeroMinWidth>
        <Hidden mdUp>
          <ConfigurationImage image={image} />
        </Hidden>
        <Hidden smDown>
          {imageFirst && <ConfigurationImage image={image} />}
        </Hidden>
        <Grid
          item
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-delay={50}
          data-sal-easing="ease"
          xs={12}
          md={6}
          container
          justify="center"
          alignItems="center"
        >
          <TextSection>
            <Heading className={headingStyles} heading={heading} />
            <Text>{text}</Text>
          </TextSection>
        </Grid>
        <Hidden smDown>
          {!imageFirst && <ConfigurationImage image={image} />}
        </Hidden>
      </Grid>
    </ImageSectionWrapper>
  );
};

export const ImageInfoSection = styled(ImageInfoSectionInner)``;
