import React from "react";
import { CarGrid } from "../components/Home/CarGrid/CarGrid";
import { DesignYouOwnInterior } from "../components/Home/DesignYouOwnInterior/DesignYouOwnInterior";
import { Faq } from "../components/Home/Faq/Faq";
import { Gallery } from "../components/Home/Gallery/Gallery";
import { Hero } from "../components/Home/Hero/Hero";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const IndexPage = () => (
	<Layout lightHeader>
		<SEO description="Slovenský výrobca pohrebných vozidiel." />
		<Hero />
		<CarGrid />
		<DesignYouOwnInterior />
		<Gallery />
		<Faq />
	</Layout>
);

export default IndexPage;
