import { Container, Grid, useMediaQuery } from "@material-ui/core";
import { Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { routes } from "../../../lib/routes";
import { CDButton } from "../../Shared/Button/CDButton";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { muiTheme } from "../../Shared/Theme/Theme";
import { UniversalGallery } from "../../Shared/UniversalGallery/UniversalGallery";
import { useHomeGalleryPhotos } from "./hooks/useHomeGalleryPhotos";

export interface GalleryProps {
  className?: string;
}

const headingStyles = css`
  .heading {
    &::after {
      right: 0;
      left: 0;
      margin: auto;
    }
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;
`;

const GalleryInner = (props: GalleryProps) => {
  const images = useHomeGalleryPhotos();
  const matches = useMediaQuery(muiTheme.breakpoints.down("sm"));

  return (
    <Section className={props.className}>
      <Container maxWidth="lg">
        <Header>
          <Heading
            className={headingStyles}
            heading="Pracujte na vyššej úrovni"
            pattern
          />
        </Header>
        <UniversalGallery images={images} />
        <Actions>
          <Link to={routes.showroom.to}>
            <CDButton hovered>Pozrieť ponuku</CDButton>
          </Link>
        </Actions>
      </Container>
    </Section>
  );
};

export const Gallery = styled(GalleryInner)`
  padding-top: 60px;
`;
