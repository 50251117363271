import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export interface InteriorElement {
  heading: string;
  text: string;
  image: FluidObject;
}

export const useInteriorElements = (): InteriorElement[] => {
  const {
    strapiHome: { elements },
  } = useStaticQuery(graphql`
    query useInteriorElements {
      strapiHome {
        elements: DesignYouInterior {
          heading
          text
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return elements.map((element: any) => ({
    heading: element.heading,
    text: element.text,
    image: element.image.childImageSharp.fluid,
  }));
};
