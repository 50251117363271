import { styled } from "linaria/react";
import { muiTheme, theme } from "../Theme/Theme";

export const Section = styled.section`
  margin-top: ${theme.spacing.sectionMarginTop};
  padding-bottom: 8rem;

  ${muiTheme.breakpoints.down('sm')} {
    margin-top: 4rem;    
  }
`;
