import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export const useHeroImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query useHeroImage {
      file(absolutePath: {}, relativePath: { eq: "home/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 600 , quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return file?.childImageSharp?.fluid;
};
