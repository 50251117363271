import { styled } from "linaria/react";
import React, { ReactNode } from "react";
import cls from "classnames";
import { CircularProgress } from "@material-ui/core";
import { css } from "linaria";
import { theme } from "../Theme/Theme";

export interface CDButtonProps extends React.HTMLProps<HTMLButtonElement> {
  secondary?: boolean;
  loading?: boolean;
  hovered?: boolean;
  white?: boolean;
  children?: ReactNode;
  className?: string;
}

export const CDButtonStyles = css`
  background: ${theme.color.primary};
  color: ${theme.color.primaryDark};
  min-width: 200px;
  padding: 12px 20px;
  font-size: 1.8rem;
  font-weight: 500;

  cursor: pointer;
  display: flex;

  border: 3px solid ${theme.color.primary};
  transition: all 230ms ease-in-out;
  justify-content: center;

  box-shadow: 0px 10px 20px rgba(210, 161, 90, 0.1);

  &.white {
    color: white;
  }

  &.hovered {
    &:hover {
      background: ${theme.color.primary};
      color: white;
    }
  }

  &.hovered,
  &:hover {
    background: transparent;
    color: ${theme.color.primary};
  }

  &.secondary {
    background: ${theme.color.primaryDark};
    border: 3px solid ${theme.color.primaryDark};

    &.hovered,
    &:hover {
      background: transparent;
      color: ${theme.color.primaryDark};
    }
  }

  &.loading {
    filter: opacity(0.5);
    cursor: progress;
  }
`;

const CDButtonInner = (props: CDButtonProps) => {
  const {
    children,
    secondary = false,
    loading = false,
    hovered = false,
    white = true,
    className = "",
  } = props;

  return (
    <div
      {...props}
      className={cls({
        [CDButtonStyles]: true,
        [className]: true,
        secondary,
        white,
        hovered,
      })}
    >
      {children}
      {loading && (
        <CircularProgress
          style={{
            height: 18,
            width: 18,
            color: theme.color.primary,
            marginLeft: 10,
          }}
        />
      )}
    </div>
  );
};

export const CDButton = styled(CDButtonInner)``;
