import { styled } from "linaria/react";
import React from "react";
import { muiTheme, theme } from "../../Shared/Theme/Theme";
import { Container, Grid, Hidden } from "@material-ui/core";
import { css } from "linaria";
import { useHeroImage } from "./hooks/useHeroImage";
import { CDButton } from "../../Shared/Button/CDButton";
import SvgScrollIndicator from "./components/ScrollIndicator/ScrollIndicator";
import { Link } from "gatsby";
import { routes } from "../../../lib/routes";
import GatsbyImage from "gatsby-image";

export interface HeroProps {
  className?: string;
}

const heroImageClass = css`
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.74));
  margin-right: -20px;

  ${muiTheme.breakpoints.down("sm")} {
    margin-top: 60px;
    margin-bottom: 40px;
  }
`;

const ImageWrapper = styled.div`
  width: 82%;
  margin: 0px 25px  0 auto;
`;

const HeroHeading = styled.h1`
  font-family: "Lato", sans-serif;
  font-size: 4.8rem;
  color: ${theme.color.light};
  margin-bottom: 20px;
  /* text-transform: uppercase; */

  ${muiTheme.breakpoints.down("sm")} {
    padding-top: 10vh;
  }
`;

const HeroSubHeading = styled.h4`
  color: ${theme.color.gray};
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 50px;
`;

const HeroInfoWrapper = styled.div`
  width: 80%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;

  ${muiTheme.breakpoints.down("sm")} {
    width: 100%;
    height: calc(100vh - 160px);
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    text-align: center;
  }
`;

const ScrollIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const HeroInner = (props: HeroProps) => {
  const image = useHeroImage();
  return (
    <section className={props.className}>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} container alignItems="flex-start">
            <HeroInfoWrapper>
              <HeroHeading
                data-sal-duration="700"
                data-sal="slide-up"
                data-sal-delay={50}
                data-sal-easing="ease"
              >
                Kvalita, vzhľad a spoľahlivosť
              </HeroHeading>
              <HeroSubHeading
                data-sal-duration="700"
                data-sal="slide-up"
                data-sal-delay={300}
                data-sal-easing="ease"
              >
                Slovenský výrobca pohrebných vozidiel.
              </HeroSubHeading>
              <div
                className="actions"
                data-sal-duration="900"
                data-sal="slide-up"
                data-sal-delay={700}
                data-sal-easing="ease"
              >
                <Link to={routes.showroom.to}>
                  <CDButton white={false}>Realizácie</CDButton>
                </Link>
              </div>
            </HeroInfoWrapper>
          </Grid>
          <Grid
            data-sal-duration="1400"
            data-sal="fade"
            data-sal-delay={1000}
            data-sal-easing="ease"
            item
            xs={12}
            md={6}
          >
            <Hidden smDown>
              <ImageWrapper>
                <GatsbyImage className={heroImageClass} fluid={image} />
              </ImageWrapper>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
      <ScrollIndicatorWrapper>
        <SvgScrollIndicator />
      </ScrollIndicatorWrapper>
    </section>
  );
};

export const Hero = styled(HeroInner)`
  background: ${theme.color.primaryDark};
  color: ${theme.color.light};
  min-height: calc(100vh - 20rem);
  position: relative;
  z-index: 0;

  margin-bottom: 14rem;
  padding-top: 20rem !important;

  .actions {
    display: flex;
  }

  ${muiTheme.breakpoints.down("sm")} {
    min-height: unset;

    .actions {
      justify-content: center;
    }
  }
`;
