import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export interface GridCar {
  name: string;
  image: FluidObject;
  slug: string;
}

export const useGridCars = (): GridCar[] => {
  const {
    allStrapiCar: { cars },
  } = useStaticQuery(graphql`
    query useGridCars {
      allStrapiCar {
        cars: nodes {
          name
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return cars.map((car: any) => ({
    name: car.name,
    slug: car.slug,
    image: car.image.childImageSharp.fluid,
  }));
};
