import * as React from "react";

function SvgScrollIndicator(props) {
  return (
    <svg width={16} height={30} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.783 8v13.333a6.783 6.783 0 01-13.566 0V8a6.783 6.783 0 0113.566 0zM0 8a8 8 0 0116 0v13.333a8 8 0 11-16 0V8zm8 9.333A3.333 3.333 0 108 24a3.333 3.333 0 000-6.667z"
        fill="#969694"
        fillOpacity={0.2}
      />
    </svg>
  );
}

export default SvgScrollIndicator;
