import { graphql, useStaticQuery } from "gatsby";

export interface Faq {
  slug: string;
  question: string;
  shortAnswer: string;
  longAnswer: string;
}

export const useHomeFaqs = (): Faq[] => {
  const {
    strapiHome: { Faq },
  } = useStaticQuery(graphql`
    query useHomeFaqs {
      strapiHome {
        Faq {
          faq {
            slug
            shortAnswer
            question
            longAnswer
          }
        }
      }
    }
  `);

  return Faq.map((faq: any) => faq.faq);
};
