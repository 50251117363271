import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";

export interface GalleryPhoto {
  publicURL: string;
  childImageSharp: {
    fluid: FluidObject;
  };
  sources?: any[];
}

export const useHomeGalleryPhotos = (): GalleryPhoto[] => {
  const {
    strapiHome: { gallery },
  } = useStaticQuery(graphql`
    query useHomeGalleryPhotos {
      strapiHome {
        gallery {
          desktop: image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          mobile: image {
            childImageSharp {
              fluid(maxWidth: 180, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return gallery.map((image: any) => ({
    publicURL: image.desktop.publicURL,
    childImageSharp: image.desktop.childImageSharp,
    sources: [
      image.desktop.childImageSharp.fluid,
      {
        ...image.mobile.childImageSharp.fluid,
        media: `(max-width: 768px)`,
      },
    ],
  }));
};
