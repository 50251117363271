import { Link } from "gatsby";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { generateQuestionRoute } from "../../../../lib/routes";
import DotPattern from "../../../Shared/misc/DotPattern/DotPattern";
import { muiTheme, theme } from "../../../Shared/Theme/Theme";
import { Faq } from "../hooks/useHomeFaqs";

export interface QuestionAnswerProps extends Faq {
  className?: string;
}

const Question = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
`;

const Answer = styled.p`
  margin-top: 20px;
  color: ${theme.color.text};
`;

const readMoreStyles = css`
  margin-top: 20px;
  color: ${theme.color.primary};
`;

const patternStyles = css`
  /* fill: ${theme.color.primary}; */
`;

const PatternWrapper = styled.div`
  position: absolute;
  top: -100px;
  right: 0;

  ${muiTheme.breakpoints.down("sm")} {
    top: -70px;
  }
`;

const QuestionAnswerInner = (props: QuestionAnswerProps) => {
  const { question, shortAnswer, slug, longAnswer } = props;
  return (
    <article className={props.className}>
      <Question>{question}</Question>
      <Answer>{shortAnswer}</Answer>
      {longAnswer.length > 0 && (
        <Link className={readMoreStyles} to={generateQuestionRoute(slug)}>
          Čítať viac
        </Link>
      )}
      <PatternWrapper>
        <DotPattern className={patternStyles} />
      </PatternWrapper>
    </article>
  );
};

export const QuestionAnswer = styled(QuestionAnswerInner)`
  display: flex;
  flex-flow: column;
  width: auto;
  flex-grow: 0;
  max-width: 440px;
  position: relative;
`;
