import { Container } from "@material-ui/core";
import { styled } from "linaria/react";
import React from "react";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { ImageInfoSection } from "./components/ImageInfoSection/ImageInfoSection";
import { useInteriorElements } from "./hooks/useInteriorElements";

export interface DesignYouOwnInteriorProps {
  className?: string;
}

const DesignYouOwnInteriorInner = (props: DesignYouOwnInteriorProps) => {
  const interiorElements = useInteriorElements();
  return (
    <Section className={props.className}>
      <Container>
        <Heading heading="Navrhnite si interiér" />
      </Container>
      <div className="elements">
        {interiorElements.map((element, index) => (
          <ImageInfoSection {...element} imageFirst={index % 2 == 0} />
        ))}
      </div>
    </Section>
  );
};

export const DesignYouOwnInterior = styled(DesignYouOwnInteriorInner)`
  max-width: 100vw;
  overflow: hidden;
`;
