import { Container, Grid } from "@material-ui/core";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Heading } from "../../Shared/Heading/Heading";
import { Section } from "../../Shared/Section/Section";
import { CarGridItem } from "./components/CarGridItem/CarGridItem";
import { useGridCars } from "./hooks/useGridCars";

export interface CarGridProps {
  className?: string;
}

const headingStyles = css`
  margin-bottom: 160px;
`;

const CarGridInner = (props: CarGridProps) => {
  const cars = useGridCars();
  return (
    <Section className={props.className}>
      <Container>
        <Heading className={headingStyles} heading="Vyberte si vozidlo" />
        <div className="grid-wrapper">
          <Grid container spacing={2}>
            {cars.map((car, index) => (
              <Grid
                data-sal-duration="700"
                data-sal="slide-up"
                data-sal-delay={index * 50}
                data-sal-easing="ease"
                key={car.slug}
                item
                xs={6}
                lg={3}
              >
                <CarGridItem {...car} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </Section>
  );
};

export const CarGrid = styled(CarGridInner)`
  .grid-wrapper {
    margin-top: 20px;
  }
  padding-bottom: 2rem;
`;
